import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { UserAuth } from '../contexts/AuthContext'
import Autocomplete from '../components/Autocomplete'
import { toast } from 'react-toastify';

const FetchForm = () => {
  const navigate = useNavigate()
  
  const {dataAPI, logout } = UserAuth()

  const [inputValue, setInputValue] = useState('')
  const [dropdownValue, setDropdownValue] = useState(1)

  const handleInputChange = (value) => {
    setInputValue(value)
  };

  const handleDropdownChange = (value) => {
    setDropdownValue(value);
  }
  // const [APIdata, setAPIdata] = useState('')

  const handleLogout = async () => {
    try {
      
      await logout();
      navigate('/');
    } catch (e) {
      console.log(e.message);
    }
  }

  const fetchEmail = async () => {
    const response = await axios.get(`https://europe-west3-compass-gcp.cloudfunctions.net/Compass_Api_no_auth/api/bigquery/fetchOne?email=${inputValue}`)
    const data = response.data
    dataAPI(data)

  };

  const FetchPhoneNumber = async () => {
    // e.preventDefault();
    const response = await axios.get(`https://europe-west3-compass-gcp.cloudfunctions.net/Compass_Api_no_auth/api/bigquery/fetchOneByNumber?phone_number=${inputValue}`)
    const data = response.data
    dataAPI(data)
  }

  const FetchFullName = async () => {
    // e.preventDefault();
    
    
    const response = await axios.get(`https://europe-west3-compass-gcp.cloudfunctions.net/Compass_Api_no_auth/api/bigquery/fetchByFullName?full_name=${inputValue}`)
    const data = response.data
    
    if(data.length !== 0){
      dataAPI(data)
    }else{
      dataAPI([])
    }
    
  }

  const FetchByAssignment = async () => {
    // e.preventDefault();
    
    const response = await axios.get(`https://europe-west3-compass-gcp.cloudfunctions.net/Compass_Api_no_auth/api/bigquery/FetchByAssignment?assignment_name=${inputValue}`)
    const data = response.data
    console.log(data)
    if(data.length !== 0){
      dataAPI(data)
    }else{
      dataAPI([])
    }
    
  }

  function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  function validatePhoneNumber(phoneNumber) {
    const phoneRegex = /^[0-9]{8,16}$/;
    return phoneRegex.test(phoneNumber);
  }

  function validateFullName(fullName) {
    const words = fullName.split(' ');
    return words.length >= 1;
  }

  const handleSearch = (e) => {
    e.preventDefault();
    
    if(dropdownValue === 2) {
      if (validateEmail(inputValue)) {
          fetchEmail().then(()=>{navigate('/results');})
      } 
    }
  
    else if(dropdownValue === 3){
      if (validatePhoneNumber(inputValue)) {
          FetchPhoneNumber().then(()=>{navigate('/results');})
      } else {
        toast.error('PHONENUMBER IS NOT VALID', { theme: "colored", hideProgressBar: true });
      }
    ;}

    else if(dropdownValue === 1){
      if (validateFullName(inputValue)) { 
        
        FetchFullName().then(()=>{navigate('/PeopleResults')})
      }
    }

    else if(dropdownValue === 4){
      FetchByAssignment().then(()=>{navigate('/PeopleResults/Assignment')})
    }
}

const fetchID = async (value) => {
  
  try {
    const response = await axios.get(
      `https://europe-west3-compass-gcp.cloudfunctions.net/Compass_Api_no_auth/api/bigquery/fetchFromID?id=${value}`
    );
    const data = response.data;
    dataAPI(data);
  } catch (error) {
    console.log(error);
  } 
}


  return (
    <div className='flex flex-col max-w-xl mx-auto'>
      <div className="bg-white py-8 px-6 shadow rounded-lg sm:px-10">
        <h2 className="mb-6 text-center text-2xl text-gray-900 font-link font-semibold">Search for <span className='text-[#1EADE1] text-3xl font-bold'>Candidate</span></h2>
        <form className="mb-0 space-y-6 mt-12">
          <div>
              <Autocomplete onValueChange={handleInputChange} onDropdownChange={handleDropdownChange} inputValue={inputValue} dropdownValue={dropdownValue} onSearch={handleSearch} />
              {/*<AutoComplete />*/}
          </div>
        </form>

        <div  className='flex justify-center'>
            <button type="submit" onClick={handleLogout} className="w-1/4 float-right mt-5 mr-5  py-2 px-2 rounded-md shadow-sm text-sm font-medium text-white bg-[#F51E1E] hover:bg-[#F50501]">Logout</button>
          </div>
      </div>
    </div>
  )
}

export default FetchForm;