import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useSelect } from "react-select-search";

const Autocomplete = ({ onSearch, onValueChange, onDropdownChange, value }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");


  const [selectedValue, setSelectedValue] = useState(1);
  const [searchResults, setSearchResults] = useState([]);
  const [isInputFocused, setInputFocused] = useState(false);
  const [isInputFocusedClick, setInputFocusedClick] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(searchTerm); // Set debounced value after delay
    }, 500) // 500 ms delay

    // Cleanup timeout if inputValue changes before delay is finished
    return () => {
      clearTimeout(handler)
    }
  }, [searchTerm])

  useEffect(() => {
    if (debouncedValue) {
      if (selectedValue === 1) {
        FetchFullName(debouncedValue)
      }
      if (selectedValue === 2) {
        fetchEmail(debouncedValue)
      }
      if (selectedValue === 3) {
        FetchPhoneNumber(debouncedValue)
      }
      if (selectedValue === 4) {
        FetchAssignments(debouncedValue)
      }
    }
  }, [debouncedValue])

  const handleInputChange = (event) => {

    let inputValue = event.target.value
    inputValue = inputValue.replace("  ", " ")

    setSearchTerm(inputValue)
    onValueChange(inputValue)
    setInputFocusedClick(true)
  }

  const FetchFullName = async (autoname) => {
    // e.preventDefault();

    const response = await axios.get(
      `https://europe-west3-compass-gcp.cloudfunctions.net/Compass_Api_no_auth/api/bigquery/fetchByFullName?full_name=${autoname}`
    )

    const data = response.data
    setSearchResults(data)
  };

  const fetchEmail = async (autoemail) => {
    const response = await axios.get(
      `https://europe-west3-compass-gcp.cloudfunctions.net/Compass_Api_no_auth/api/bigquery/searchFetchByEmail?email=${autoemail}`
    );
    const data = response.data;
    setSearchResults(data);
  };

  const FetchAssignments = async (assignment_name) => {
    // e.preventDefault();
    const response = await axios.get(
      `https://europe-west3-compass-gcp.cloudfunctions.net/Compass_Api_no_auth/api/bigquery/fetchAssignments?assignment_name=${assignment_name}`
    );
    const data = response.data
    setSearchResults(data);
  };

  const FetchPhoneNumber = async (autophone) => {
    // e.preventDefault();
    const response = await axios.get(
      `https://europe-west3-compass-gcp.cloudfunctions.net/Compass_Api_no_auth/api/bigquery/searchFetchByPhoneNumber?phone_number=${autophone}`
    );
    const data = response.data
    setSearchResults(data);
  };

  const handleInputFocus = () => {
    setInputFocused(true);
  };

  const handleInputBlur = () => {
    setInputFocused(false);
  };

  const handleSelectChange = (event) => {
    const selectedValue = parseInt(event.target.value);
    setSelectedValue(selectedValue);
    onDropdownChange(selectedValue);
    setSearchTerm("");
  };

  const handleClick = (result) => {
    
    const selectedValueText =
      selectedValue === 2
        ? result.email
        : selectedValue === 1
        ? `${result.first_name} ${result.last_name}`
        : selectedValue === 4
        ? `${result.assignment_name}`
        : result.phone_number;
    onValueChange(selectedValueText)
    setSearchTerm(selectedValueText)
    setInputFocusedClick(false)
  };

  const filteredResults = searchTerm
    ? searchResults
        .filter((item) => {
          const { first_name, last_name, email, phone_number, assignment_name } = item;

          if (selectedValue === 2 && email) {
            return email.toLowerCase().includes(searchTerm.toLowerCase());
          }

          if (selectedValue === 3 && phone_number) {
            return phone_number.includes(searchTerm);
          }

          if (selectedValue === 1) {
            const fullName = `${first_name} ${last_name}`;
            return fullName.toLowerCase().includes(searchTerm.toLowerCase());
          }

          if (selectedValue === 4) {
            const assignmentName = `${assignment_name}`
            
            return assignmentName.toLowerCase().includes(searchTerm.toLowerCase());
          }

          return false; // Handle unknown filter criteria
        })
        .filter((item) => item !== null)
    : []
  
  //console.log("in autocomplete component")

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setInputFocusedClick(false);
    }
  };

  document.addEventListener("click", handleClickOutside);

  /*
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setInputFocusedClick(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  */

  return (
    <div>
      <div className="relative w-full">
        <select
          className="w-full text-gray-700 bg-white border rounded shadow h-10 pl-2.5"
          value={selectedValue}
          onChange={handleSelectChange}
        >
          <option value={1}>Name</option>
          <option value={2}>Email</option>
          <option value={3}>Phone number</option>
          <option value={4}>Assignments</option>
        </select>
      </div>
      <div className="mt-5">
        <div className="grid grid-cols-4">
          <div className="col-span-3">
            <input
              type="text"
              value={searchTerm}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              className="w-full border rounded h-10 p-2.5 rounded-tr-none rounded-br-none"
              placeholder="Type Here..."
            />
            <div className="relative h-2 w-full">
              {isInputFocusedClick && filteredResults.length > 0 && (
                <ul
                  className="bg-white border-r-2 border-b-2 border-l-2 border-gray-200"
                  ref={dropdownRef}
                >
                  {filteredResults.map((result, index) => (
                    <li
                      key={index}
                      onClick={() => handleClick(result)}
                      className="px-2 py-4"
                      onFocus={handleInputFocus}
                    >
                      {selectedValue === 2
                        ? result.email
                        : selectedValue === 1
                        ? `${result.first_name} ${result.last_name}`
                        : selectedValue === 4
                        ? `${result.assignment_name}`
                        : result.phone_number}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className="col-span-1">
            <button
              type="submit"
              onClick={onSearch}
              className="w-full button-custom px-4 rounded-md rounded-tl-none rounded-bl-none shadow-sm text-sm font-medium text-white bg-[#1EADE1] hover:bg-[#1FAEF9]"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Autocomplete
