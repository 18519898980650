import React from "react";

const CommentsModal = (props) => {
  console.log(props.person);
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="relative bg-white w-[90vw] h-[90vh] p-8 rounded-lg overflow-auto">
        <button
          className="absolute top-4 right-4 bg-red-500 text-white rounded-full w-8 h-8 flex items-center justify-center hover:bg-red-600"
          onClick={props.toggleCommentsModal}
        >
          &times;
        </button>

        <div className="text-gray-700 space-y-6">
          <h2 className="text-2xl font-bold mb-4">Candidate Information</h2>

          {/* Name */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Name
            </label>
            <div className="w-full p-2 border rounded-md border-gray-300 bg-gray-50">
              {props.person.name ? props.person.name : "-"}
            </div>
          </div>

          {/* Salary */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Salary
            </label>
            <div className="w-full p-2 border rounded-md border-gray-300 bg-gray-50">
              {props.person.salary ? props.person.salary : "-"}{" "}
              {props.person.currency}
            </div>
          </div>

          {/* Bonus */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Bonus
            </label>
            <div className="w-full p-2 border rounded-md border-gray-300 bg-gray-50">
              {props.person.bonus ? props.person.bonus : "-"}
            </div>
          </div>

          {/* Pension */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Pension
            </label>
            <div className="w-full p-2 border rounded-md border-gray-300 bg-gray-50">
              {props.person.pension ? props.person.pension : "-"}
            </div>
          </div>

          {/* Industry */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Industry
            </label>
            <div className="w-full p-2 border rounded-md border-gray-300 bg-gray-50">
              {props.person.industry ? props.person.industry : "-"}
            </div>
          </div>

          {/* Large Text Area */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Screening
            </label>
            <textarea
              className="w-full p-3 border rounded-md border-gray-300 bg-gray-50 h-40 overflow-y-auto"
              value={props.person.assignment.notes?.description.screening ? props.person.assignment.notes.description.screening : ""}
              readOnly
            ></textarea>
          </div>
          {/* Large Text Area */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Interview
            </label>
            <textarea
              className="w-full p-3 border rounded-md border-gray-300 bg-gray-50 h-40 overflow-y-auto"
              value={props.person.assignment.notes?.description.interview ? props.person.assignment.notes.description.interview : ""}
              readOnly
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  );
};

export { CommentsModal };
